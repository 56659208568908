<template>
    <div class="scan-page" v-if="device">

        <!-- Initial Page -->
        <template>
            <div class="container repair-container">
                <div class="row">
                    <div class="col-12">
                        <label>Select device's board size</label>
                    </div>
                    <div class="col-12">
                        <ul class="list-group selection-list mb-4">
                            <li class="list-group-item" v-for="(board, index) in boards" :key="index">
                                <label class="custom-control custom-radio mb-0">
                                    <input type="radio" name="boardSize[]" class="custom-control-input" @click="setSelectedBoardSize(board.title, board.id)" :value="board.id" />
                                    <label class="custom-control-label mb-0" :for="board.code">{{ board.title }}</label>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <b-modal
                id="boardModal"
                ref="boardModal"
                ok-title="I'm Sure"
                @ok="updateBoardType"
                size="fs"
                title="Change Board Size">
                <div class="container">

                    <!-- Make -->
                    <section class="text-center">
                        <h1 class="text-center d-block">Warning: Board size doesn't match</h1>
                        <p>The selected board size is not the same as the one against the device in the system, are you sure you have selected the correct board size?</p>
                    </section>
                </div>
            </b-modal>
        </template>

        <!-- Footer -->
        <fixed-footer
            :class="{'active': footerActive}"
            :nextLabel="this.nextRepairStep($route.name).title"
            :nextRoute="this.nextRepairStep($route.name).routeName"
            :prevRoute="this.prevRepairStep($route.name).routeName">
        </fixed-footer>

    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {

    props: ["jobId", "deviceId"],

    data() {
        return {
            boardSizeSelected: false,
            serialNumberValid: false,
            validating: false,
            selectedBoardType: {},
            boards: []
        }
    },

    mounted() {
        this.loadDeviceForJob({
            jobId: this.jobId,
            deviceId: this.deviceId,
        }).then(() => {
            this.setCurrentStep({
                jobId: this.jobId,
                deviceId: this.deviceId,
                currentStep: this.$route.name,
            });
        });
        this.getBoardSizes();
    },

    computed: {
        ...mapGetters([
            "device",
            "isJobManager",
            "nextRepairStep",
            "prevRepairStep"
        ]),

        /** Check if footer should be active */
        footerActive() {
            if (this.selectedBoardType) {
                return true;
            }
            return false;
        }
    },

    methods: {
        ...mapActions([
            "clearSerialNumberEntered",
            "displayToast",
            "loadDeviceForJob",
            "saveSerialNumber",
            "setCurrentStep"
        ]),

        setSelectedBoardSize(boardsize, boardId) {
            this.selectedBoardType = {'title': boardsize, 'id': boardId};
            if(this.selectedBoardType.title != this.device.board) {
                this.$bvModal.show('boardModal');
            }
        },

        updateBoardType() {
            this.savingBoard = true;
            this.$http.put(`/api/v4/orders/devices/${this.device.id}/update-board-type`, {
                board_type_id: this.selectedBoardType.id
            })
                .then(response => {
                    if (response.data.status_code === 200) {
                        this.savingBoard = false;
                    }
                }).catch(error => {
                alert(JSON.stringify(error.response.data.messages));
                this.savingBoard = false;
            });
        },

        getBoardSizes() {
            this.boards = [];
            this.$http.get(`/api/v4/orders/devices/${this.deviceId}/available-board-types`)
                .then(response => {
                    const availableBoardTypes = response.data.data;
                    availableBoardTypes.forEach(boardType => {
                        this.boards.push(boardType);
                    });
                });
        }
    }
}
</script>

<style scoped>

</style>